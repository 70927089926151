import React from "react";
import { withRouter } from "react-router-dom";

import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";

import { withTranslation } from "react-i18next";

import agroApi from "../services/agroApi";
import { UserContext } from "../store/UserContext";
import FilterColors from "../assets/FilterColors";

var moment = require("moment");

class GridTable extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onRowSelected = this.onRowSelected.bind(this);
    this.loadData = this.loadData.bind(this);

    this.Rows = [];
    this.intervalID = 0;
    this.customer_id = 0;
    this.devid = 0;
  }

  async loadData() {
    const User = this.context.User;
    const { history } = this.props;
    await agroApi
      .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/maingrid/cbdata", {
        headers: { token: User.token },
      })
      .then((data) => {
        this.gridApi.setRowData(data.data);
      })
      .catch((error) => {
        try {
          if (error.response.status === 401) {
            history.push("/");
          }
        } catch (err2) {
          //console.log(err2.response);
        }
        //console.log(error.response);
      });
  }

  componentDidMount() {
    this.loadData();
    this.intervalID = setInterval(this.loadData, 60000);
  }

  componentDidUpdate() {
    if (this.customer_id !== this.context.User.ActiveCustomer) {
      this.customer_id = this.context.User.ActiveCustomer;
      this.loadData();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  getRowNodeId(data) {
    return data.device_id;
  }

  onRowSelected = (event) => {
    if (event.node.selected) {
      this.devid = event.node.data.device_id;
      /*console.log("onRowSelected: " + event.node.data.device_id);
      console.log(event.node.data.latitude);
      console.log(event.node.data.longitude);
      console.log(this.props.mapref);*/
      /*const { current = {} } = this.props.mapref;
         const { leafletElement: map } = current;
         if (map) {
            map.flyTo([event.node.data.latitude, event.node.data.longitude]);
         }*/
    }
  };

  scrambleAndRefreshAll = () => {
    var params = { force: true };
    this.gridApi.refreshCells(params);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  render() {
    const { t } = this.props;

    return (
      <div className="ag-theme-balham" style={{ height: "40vh", width: "100hv" }}>
        <AgGridReact
          ref="agGrid"
          rowSelection="single"
          animateRows
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          columnDefs={[
            { headerName: t("maingrid.id"), field: "device_id", width: 80, minWidth: 50, maxWidth: 120 },
            { headerName: t("maingrid.name"), field: "device_name", width: 280, minWidth: 80, maxWidth: 400 },
            { headerName: t("maingrid.local"), field: "fence_name", width: 150, minWidth: 80, maxWidth: 400 },
            /*{
                     headerName: t("maingrid.group"),
                     field: "devicegroup_name",
                     width: 150,
                     minWidth: 80,
                     maxWidth: 400,
                  },*/
            {
              headerName: t("maingrid.ign"),
              field: "ign",
              width: 90,
              minWidth: 80,
              maxWidth: 150,
              cellRenderer: (data) => {
                var element = document.createElement("span");
                var imageElement = document.createElement("img");
                imageElement.src = "/images/circle-medium.svg";

                // visually indicate if this months value is higher or lower than last months value
                if (data.value === 1) {
                  imageElement.setAttribute("style", FilterColors("green"));
                  imageElement.title = "Ligada";
                } else {
                  imageElement.setAttribute("style", FilterColors("red"));
                  imageElement.title = "Desligada";
                }
                element.appendChild(imageElement);
                return element;
              },
            },
            {
              headerName: t("maingrid.last_transmition"),
              field: "last_transmition",
              width: 90,
              minWidth: 80,
              maxWidth: 150,
              cellRenderer: (data) => {
                var element = document.createElement("span");
                var imageElement = document.createElement("img");
                imageElement.src = "/images/wifi.svg";

                // visually indicate if this months value is higher or lower than last months value
                if (data.value < 21) {
                  imageElement.setAttribute("style", FilterColors("green"));
                  imageElement.title = "Online";
                } else {
                  imageElement.setAttribute("style", FilterColors("red"));
                  imageElement.title = "Offline";
                }
                element.appendChild(imageElement);
                return element;
              },
            },
            {
              headerName: t("maingrid.devicetimestamp"),
              field: "devicetimestamp",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              cellRenderer: (data) => {
                if (data.value != null) return moment(data.value).utc(0).format("YYYY/MM/DD HH:mm");
                else return null;
              },
            },
            {
              headerName: t("maingrid.servertimestamp"),
              field: "servertimestamp",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              cellRenderer: (data) => {
                if (data.value != null) return moment(data.value).utc(0).format("YYYY/MM/DD HH:mm");
                else return null;
              },
            },
            {
              headerName: t("maingrid.speed"),
              field: "velocidade",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatspeed,
            },
            {
              headerName: t("maingrid.rpm"),
              field: "rpm",
              width: 90,
              minWidth: 80,
              maxWidth: 150,
              valueFormatter: formatrpm,
            },
            {
              headerName: t("maingrid.temperatura_liquido_arrefecimento"),
              field: "temperatura_liquido_arrefecimento",
              width: 120,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formattemperature,
            },
            {
              headerName: t("maingrid.vazao_combustivel"),
              field: "vazao_combustivel",
              width: 100,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatvazao,
            },
            {
              headerName: t("maingrid.nivel_combustivel"),
              field: "nivel_combustivel",
              width: 100,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formattanque,
            },
            {
              headerName: t("maingrid.tensao_bateria"),
              field: "tensao_bateria",
              width: 100,
              minWidth: 80,
              maxWidth: 120,
              valueFormatter: formatvoltage,
            },
            {
              headerName: t("maingrid.percentual_torque_motor"),
              field: "percentual_torque_motor",
              width: 120,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formattanque,
            },
            {
              headerName: t("maingrid.pressao_oleo_motor"),
              field: "pressao_oleo_motor",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatkpa,
            },
            {
              headerName: t("maingrid.pressao_entrega_combustivel_motor"),
              field: "pressao_entrega_combustivel_motor",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatkpa,
            },

            {
              headerName: t("maingrid.latitude"),
              field: "latitude",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatlatlon,
            },
            {
              headerName: t("maingrid.longitude"),
              field: "longitude",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatlatlon,
            },
            {
              headerName: t("maingrid.total_horas_funcionamento_motor"),
              field: "total_horas_funcionamento_motor",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formathorimetro,
            },
            {
              headerName: t("maingrid.total_combustivel_usado"),
              field: "total_combustivel_usado",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formattotlacombusado,
            },
            {
              headerName: t("maingrid.temperatura_oleo_hidraulico"),
              field: "temperatura_oleo_hidraulico",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formattemperature,
            },
            {
              headerName: t("maingrid.piloto_automatico"),
              field: "piloto_automatico",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatpilotoautomatico,
            },
            {
              headerName: t("maingrid.descarga_graos"),
              field: "descarga_graos",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatestado,
            },
            {
              headerName: t("maingrid.estado_unidade_colheita_algodao"),
              field: "estado_unidade_colheita_algodao",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatestado,
            },
            {
              headerName: t("maingrid.estado_plataforma_algodao"),
              field: "estado_plataforma_algodao",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatestado2,
            },
            {
              headerName: t("maingrid.estado_embalar_algodao"),
              field: "estado_embalar_algodao",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatestado,
            },
            {
              headerName: t("maingrid.bomba_dagua_pu"),
              field: "bomba_dagua_pu",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatestado,
            },
            {
              headerName: t("maingrid.liberando_liquido_pu"),
              field: "liberando_liquido_pu",
              width: 150,
              minWidth: 80,
              maxWidth: 200,
              valueFormatter: formatestado,
            },
            { headerName: "NetId", field: "device_netid", width: 280, minWidth: 80, maxWidth: 400, editable: true },
          ]}
          rowData={this.Rows}
          modules={AllCommunityModules}
          getRowNodeId={this.getRowNodeId}
          onRowSelected={this.onRowSelected}
          onGridReady={this.onGridReady}
        ></AgGridReact>
      </div>
    );
  }
}

function formatvoltage(params) {
  if (params.value != null)
    if (params.value > 0) return params.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " V";
    else return "";
  else return null;
}

function formatestado2(params) {
  if (params.value != null)
    if (params.value > 0) {
      if (params.value === 0) return "Desligado";
      else if (params.value === 1) return "Abaixando";
      else if (params.value === 2) return "Levantando";
      else return "";
    } else return "";
  else return null;
}

function formatestado(params) {
  if (params.value != null)
    if (params.value > 0) {
      if (params.value === 0) return "Desligado";
      else if (params.value === 1) return "Ligado";
      else if (params.value === 2) return "Erro";
      else return "";
    } else return "";
  else return null;
}

function formatpilotoautomatico(params) {
  if (params.value != null)
    if (params.value > 0) {
      if (params.value === 0) return "Desligado";
      else if (params.value === 1) return "Ligado";
      else if (params.value === 2) return "Erro";
      else return "";
    } else return "";
  else return null;
}

function formathorimetro(params) {
  if (params.value != null)
    if (params.value > 0) return params.value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    else return "";
  else return null;
}

function formatlatlon(params) {
  if (params.value != null)
    if (params.value > -999) return params.value.toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 });
    else return "";
  else return null;
}

function formatspeed(params) {
  if (params.value != null)
    if (params.value >= 0) return params.value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + " Km/h";
    else return "";
  else return null;
}

function formatrpm(params) {
  if (params.value != null) {
    if (params.value < 0) return "";
    else
      return params.value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false,
      });
  } else return null;
}

function formattanque(params) {
  if (params.value != null) {
    if (params.value > -1)
      return (
        params.value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: false,
        }) + " %"
      );
    else return "";
  } else return "";
}

function formatvazao(params) {
  if (params.value != null) {
    if (params.value < 0) return "";
    else
      return (
        params.value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: false,
        }) + " L/h"
      );
  } else return null;
}

function formattotlacombusado(params) {
  if (params.value != null) {
    if (params.value < 0) return "";
    else
      return (
        params.value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: false,
        }) + " L"
      );
  } else return null;
}

function formatkpa(params) {
  if (params.value != null) {
    if (params.value < 0) return "";
    else
      return (
        params.value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          useGrouping: false,
        }) + " kPa"
      );
  } else return null;
}

function formattemperature(params) {
  if (params.value != null) {
    if (params.value < 0) return "";
    else
      return (
        params.value.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          useGrouping: false,
        }) + " °C"
      );
  } else return null;
}

export default withRouter(withTranslation()(GridTable));
