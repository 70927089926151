import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../store/UserContext";
import { withStyles } from "@material-ui/core/styles";

import agroApi from "../services/agroApi";

import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

import { red, blue, amber } from "@material-ui/core/colors";
import Icon from "@material-ui/core/Icon";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import WarningIcon from "@material-ui/icons/Warning";

var moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "80vh",
    overflow: "scroll",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 120,
  },
  table: {
    minWidth: "100%",
  },
  button: {
    margin: theme.spacing(2),
  },
  "& .MuiTextField-root": {
    margin: theme.spacing(1),
    width: "25ch",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const GreenRadio = withStyles({
  root: {
    color: blue[400],
    "&$checked": {
      color: blue[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function MaintenanceServiceOrder() {
  const classes = useStyles();
  const { User } = useContext(UserContext);
  const [events, setEvents] = useState([]);
  const history = useHistory();
  const [reload, setReload] = React.useState(0);
  const { t } = useTranslation();
  const [eventStatus, setEventStatus] = React.useState("2");
  const [adata, setAdata] = React.useState({});
  const [openNewItem, setOpenNewItem] = React.useState(false);
  const [newItem, setNewItem] = React.useState({ item: "", unit: "un", qtd: "0", price: "0" });
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [filtro, setFiltro] = React.useState(" ");
  const [adataChanged, setAdataChanged] = React.useState(false);
  const [newItemError, setNewItemError] = React.useState({ itemid: "", item: false, unit: false, qtd: false, price: false, cc: "" });
  const [openBackdrop, setOpenBackDrop] = React.useState(false);
  const [confirmaFinalizar, setConfirmaFinalizar] = React.useState(false);
  const [confirmaSelectedIndex, setConfirmaSelectedIndex] = React.useState(-1);

  useEffect(() => {
    agroApi
      .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/events3", { headers: { token: User.token }, params: { event_status: eventStatus } })
      .then((data) => {
        setEvents(data.data.rows);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          history.push("/");
        }
      });
    setAdataChanged(false);
    setOpenNewItem(false);
    setAdata({});
    setSelectedIndex(-1);
  }, [User.ActiveCustomer, User.customers, User.token, history, reload, eventStatus]);

  const handleListItemClick = (valor) => {
    if (adataChanged) {
      setConfirmaSelectedIndex(valor);
    } else {
      setSelectedIndex(valor);
      setAdata(JSON.parse(JSON.stringify(events[valor])));
    }
  };

  const handleConfirmaSelectedIndex = (status) => {
    setConfirmaSelectedIndex(-1);
  };

  const handleChange = (event) => {
    setEventStatus(event.target.value);
  };

  const handleClickOpenNewItem = () => {
    setNewItem({ itemid: "", item: "", unit: "un", qtd: "0", price: "0", cc: "" });
    setNewItemError({ item: false, unit: false, qtd: false, price: false });
    setOpenNewItem(true);
  };

  const handleCloseNewItem = () => {
    setOpenNewItem(false);
  };

  const handleCloseNewItemOk = () => {
    if (newItem.item === undefined || newItem.item === "") {
      setNewItemError({ ...newItemError, item: true });
      return;
    }
    if (newItem.unit === undefined || newItem.unit === "") {
      setNewItemError({ ...newItemError, unit: true });
      return;
    }
    /*if (newItem.qtd === undefined || newItem.qtd === "" || newItem.qtd === 0) {
      setNewItemError({ ...newItemError, qtd: true });
      return;
    }
    if (newItem.price === undefined || newItem.price === "" || newItem.price === 0) {
      setNewItemError({ ...newItemError, price: true });
      return;
    }*/

    if (adata.inventory_items === undefined || adata.inventory_items === null) adata.inventory_items = [newItem];
    else adata.inventory_items.push(newItem);
    //setAdata(adata);

    setAdataChanged(true);
    setOpenNewItem(false);
  };

  const handleItemIdChange = (event) => {
    setNewItem({ ...newItem, itemid: event.target.value });
  };

  const handleItemChange = (event) => {
    setNewItem({ ...newItem, item: event.target.value });
  };

  const handleUnitChange = (event) => {
    setNewItem({ ...newItem, unit: event.target.value });
  };

  const handleQtdChange = (event) => {
    if (event.target.value !== "") {
      var re = /^[-.]?\d+(?:[.]\d*?)?$/;
      if (re.test(event.target.value)) setNewItem({ ...newItem, qtd: event.target.value });
    } else {
      setNewItem({ ...newItem, qtd: "" });
    }
  };

  const handlePriceChange = (event) => {
    if (event.target.value !== "") {
      var re = /^[-.]?\d+(?:[.]\d*?)?$/;
      if (re.test(event.target.value)) setNewItem({ ...newItem, price: event.target.value });
    } else {
      setNewItem({ ...newItem, price: "" });
    }
  };

  const handleCCChange = (event) => {
    setNewItem({ ...newItem, cc: event.target.value });
  };

  const handleCloseTxt = (event) => {
    setAdataChanged(true);
    setAdata({ ...adata, event_closetxt: event.target.value });
  };

  const handleClickGravarAlteracoes = () => {
    setOpenBackDrop(true);
    events[selectedIndex] = adata;

    agroApi
      .put("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/events/" + adata.event_id, { inventory_items: adata.inventory_items, event_closetxt: adata.event_closetxt }, { headers: { token: User.token } })
      .then(() => {
        setAdataChanged(false);
        setOpenBackDrop(false);
      })
      .catch((error) => {
        setOpenBackDrop(false);
        if (error.response.status === 401) {
          history.push("/");
        }
      });
  };

  const handleClickCancelarAlteracoes = () => {
    setAdataChanged(false);
    setAdata(JSON.parse(JSON.stringify(events[selectedIndex])));
  };

  const handleClickFinalizar = () => {
    setConfirmaFinalizar(true);
  };

  const handleConfirmaFinalizar = (status) => {
    setConfirmaFinalizar(false);

    if (status) {
      setOpenBackDrop(true);

      agroApi
        .put("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/events/" + events[selectedIndex].event_id + "/close", {}, { headers: { token: User.token } })
        .then(() => {
          setOpenBackDrop(false);
          setReload(reload + 1);
        })
        .catch((error) => {
          setOpenBackDrop(false);
          if (error.response.status === 401) {
            history.push("/");
          }
        });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={3} sm={3} lg={3} xl={3}>
        <Tabs value={0} variant="fullWidth" indicatorColor="primary" textColor="primary" aria-label="icon label tabs example">
          <Tab
            icon={
              <Badge
                color="secondary"
                badgeContent={0}
                max={999}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                overlap="rectangular"
              >
                <PhoneIphoneIcon />
              </Badge>
            }
            label="Apontamentos"
          />
        </Tabs>

        <Box display="flex" width={"100%"} alignItems="center" justifyContent="center">
          <GreenRadio size="small" checked={eventStatus === "2"} onChange={handleChange} value="2" name="radio-button-demo" inputProps={{ "aria-label": "2" }} />
          Em aberto
          <GreenRadio size="small" checked={eventStatus === "9"} onChange={handleChange} value="9" name="radio-button-demo" inputProps={{ "aria-label": "9" }} />
          Encerrados
        </Box>
        <Box display="flex" width={"100%"} alignItems="center" justifyContent="center">
          <List className={classes.root} dense={true} component="nav" style={{ height: "75vh" }}>
            {events !== undefined &&
              events
                .filter((e) => (e.device_id + " - " + e.device_name + ", " + e.maintenancetype_description + (e.driver_name !== "" ? " (" + e.driver_name + ")" : "")).includes(filtro))
                .map((e, idx) => (
                  <ListItem button key={`event-${e.event_id}`} divider={idx !== events.length - 1} onClick={() => handleListItemClick(idx)} selected={selectedIndex === idx}>
                    <Icon style={e.eventtype_severity === 1 ? { color: blue[500] } : e.eventtype_severity === 2 ? { color: amber[300] } : e.eventtype_severity === 3 ? { color: red[300] } : { color: red[800] }}>
                      {e.eventtype_severity === 1 || e.eventtype_severity === 2 ? <ErrorOutlineOutlinedIcon /> : <WarningIcon />}
                    </Icon>
                    <ListItemText
                      style={{ marginLeft: 10 }}
                      primary={e.device_id + " - " + e.device_name + ", " + e.maintenancetype_description + (e.driver_name !== "" ? " (" + e.driver_name + ")" : "")}
                      secondary={
                        t(e.eventtype_name) +
                        " " +
                        moment(e.event_datetime)
                          .utc(0)
                          .add(User.customers[User.ActiveCustomer].customer_tz + User.customers[User.ActiveCustomer].customer_dls, "hours")
                          .format("DD/MM/YYYY HH:mm")
                      }
                    ></ListItemText>
                  </ListItem>
                ))}
          </List>
        </Box>
      </Grid>
      <Grid item xs={9} sm={9} lg={9} xl={9}>
        <div style={{ width: "100%hv" }}>
          <Tabs value={0} variant="fullWidth" indicatorColor="primary" textColor="primary" aria-label="icon label tabs example">
            <Tab
              icon={
                <Badge
                  color="secondary"
                  badgeContent={0}
                  max={999}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  overlap="rectangular"
                >
                  <PhoneIphoneIcon />
                </Badge>
              }
              label="Dados do Apontamento"
            />
          </Tabs>
          <br />
          <Box display="flex" width={"100%"} alignItems="left" justifyContent="left">
            <Typography variant="h6" component="h6">
              # {adata.event_id}
            </Typography>
            <br />
            <br />
          </Box>
          <Grid container>
            <Grid item xs={4} sm={4} lg={4} xl={4}>
              <Box display="flex" width={"100%"} height={"10vh"} alignItems="left" justifyContent="left">
                <Typography variant="body2">
                  Local: {adata.device_name} <br />
                  Data:{" "}
                  {adata.device_name !== undefined &&
                    moment(adata.event_datetime)
                      .utc(0)
                      .add(User.customers[User.ActiveCustomer].customer_tz + User.customers[User.ActiveCustomer].customer_dls, "hours")
                      .format("DD/MM/YYYY HH:mm ")}
                  <br />
                  Relato: {adata.maintenancetype_description}
                  <br />
                  Informação complementar: {adata.event_text !== undefined && adata.event_text}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8} sm={8} lg={8} xl={8}></Grid>
          </Grid>
          <Box display="flex" width={"80%"} height={"7vh"} alignItems="left" justifyContent="left">
            <Button disabled={selectedIndex === -1} className={classes.button} variant="contained" size="small" color="secondary" onClick={handleClickOpenNewItem}>
              Novo Item
            </Button>
            <Dialog open={openNewItem} onClose={handleCloseNewItem} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
              <DialogTitle id="form-dialog-title">Novo Item</DialogTitle>
              <DialogContent dividers>
                <TextField error={newItemError.itemid} value={newItem.itemid} name="newItem_id" label="Código" id="newItem_id" InputProps={{ startAdornment: <InputAdornment position="start"> </InputAdornment>, maxLength: 2 }} onChange={(event) => handleItemIdChange(event)} />
                <br />
                <br />
                <TextField error={newItemError.item} value={newItem.item} name="newItem_desc" label="Descrição do item" id="newItem_desc" InputProps={{ startAdornment: <InputAdornment position="start"> </InputAdornment>, maxLength: 250 }} onChange={(event) => handleItemChange(event)} fullWidth />
                <br />
                <br />
                <TextField error={newItemError.unit} value={newItem.unit} name="newItem_un" label="Unidade" id="newItem_un" InputProps={{ startAdornment: <InputAdornment position="start"> </InputAdornment>, maxLength: 2 }} onChange={(event) => handleUnitChange(event)} />
                <br />
                <br />
                <TextField error={newItemError.qtd} value={newItem.qtd} name="newItem_qtd" label="Qtd" id="newItem_qtd" InputProps={{ startAdornment: <InputAdornment position="start"> </InputAdornment>, maxLength: 9 }} onChange={(event) => handleQtdChange(event)} />
                <br />
                <br />
                <TextField
                  error={newItemError.price}
                  value={newItem.price}
                  name="newItem_price"
                  label="Preço"
                  id="newItem_price"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    maxLength: 12,
                  }}
                  onChange={(event) => handlePriceChange(event)}
                />
                <br />
                <br />
                <TextField error={newItemError.cc} value={newItem.cc} name="newItem_cc" label="Cento de Custo" id="newItem_cc" InputProps={{ startAdornment: <InputAdornment position="start"> </InputAdornment>, maxLength: 9 }} onChange={(event) => handleCCChange(event)} />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseNewItemOk()} color="primary">
                  Ok
                </Button>
                <Button onClick={() => handleCloseNewItem()} color="primary">
                  Cancela
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          <Box display="flex" width={"90%"} height={"30vh"} alignItems="left" justifyContent="left">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Centro de Custo</TableCell>
                    <TableCell align="right">Unidade</TableCell>
                    <TableCell align="right">Qtd</TableCell>
                    <TableCell align="right">Preço</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adata.inventory_items !== undefined &&
                    adata.inventory_items !== null &&
                    adata.inventory_items.map((row, idx) => (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">
                          {row.itemid}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.item}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.cc}
                        </TableCell>
                        <TableCell align="right">{row.unit}</TableCell>
                        <TableCell align="right">{row.qtd}</TableCell>
                        <TableCell align="right">{row.price}</TableCell>
                        <TableCell align="right">{row.qtd * row.price}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <br />
          <TextField
            value={adata.event_closetxt}
            disabled={selectedIndex === -1}
            id="outlined-multiline-static"
            label="Observação"
            multiline
            minRows={4}
            maxRows={4}
            variant="outlined"
            fullWidth
            style={{ width: "90%" }}
            onChange={(event) => handleCloseTxt(event)}
            InputProps={{
              startAdornment: <InputAdornment position="start"> </InputAdornment>,
              maxLength: 2048,
            }}
          />
          <Box display="flex" width={"90%"} alignItems="center" justifyContent="center">
            <Button
              disabled={!adataChanged}
              className={classes.button}
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => {
                handleClickGravarAlteracoes();
              }}
            >
              Gravar as alterações
            </Button>
            <Button
              disabled={!adataChanged}
              className={classes.button}
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => {
                handleClickCancelarAlteracoes();
              }}
            >
              Cancela as alterações
            </Button>
            <Button
              disabled={selectedIndex === -1 || adata.event_status === 9}
              className={classes.button}
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => {
                handleClickFinalizar();
              }}
            >
              Encerrar Ordem Serviço
            </Button>
          </Box>
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog open={confirmaFinalizar} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Atenção !"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Confirma o encerramento deste evento?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleConfirmaFinalizar(false);
                }}
                color="primary"
              >
                Não
              </Button>
              <Button
                onClick={() => {
                  handleConfirmaFinalizar(true);
                }}
                color="primary"
                autoFocus
              >
                Sim, eu confirmo
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={confirmaSelectedIndex > -1} aria-labelledby="confirmaSelectedIndex" aria-describedby="confirmaSelectedIndex">
            <DialogTitle id="confirmaSelectedIndex">{"Atenção !"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="confirmaSelectedIndex">As alterações feitas não foram gravadas!</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleConfirmaSelectedIndex(true)} color="primary">
                Ok!
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Grid>{" "}
    </Grid>
  );
}
