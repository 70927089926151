import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PhonelinkEraseIcon from "@material-ui/icons/PhonelinkErase";
import CropFreeIcon from "@material-ui/icons/CropFree";
import { UserContext } from "../store/UserContext";
import agroApi from "../services/agroApi";
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

var moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "40ch",
      marginTop: theme.spacing(1),
    },
    "& .NativeSelect-root": {
      margin: theme.spacing(1),
      width: "30ch",
      marginTop: theme.spacing(1),
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  selectEmpty: {
    marginTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(2),
  },
}));

export default function PhoneGrid() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { User } = useContext(UserContext);
  const [rowData, setRowData] = useState([]);
  const [newUser, setNewUser] = React.useState("");
  const history = useHistory();
  const [qrCodeLiberacao, setQrCodeLiberacao] = useState("");
  const [userId, setUserId] = useState(0);
  const [reload, setReload] = React.useState(0);
  const [mostraQRCode, setMostraQRCode] = useState(false);

  useEffect(() => {
    function LoadData() {
      agroApi
        .get("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/phones", {
          headers: { token: User.token },
        })
        .then((data) => {
          setRowData(data.data);
          setReload(0);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            history.push("/");
          }
        });
    }
    const timeoutId = setTimeout(() => {
      LoadData();
    }, 30000);
    LoadData();
    setNewUser("");
    setQrCodeLiberacao("");
    return () => clearTimeout(timeoutId);
  }, [User.ActiveCustomer, User.customers, User.token, history, reload]);

  function ChangePhoneStatus(userid, status) {
    agroApi
      .post(
        "/customers/" + User.customers[User.ActiveCustomer].customer_id + "/phones/status",
        { user_id: userid, status: status },
        {
          headers: { token: User.token },
        }
      )
      .then(() => {
        setReload(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          history.push("/");
        }
      });
  }

  const onRowSelected = (event) => {
    if (event.node.selected) {
      setUserId(event.node.data.user_id);
      if (event.node.data.user_pass == null) {
        setQrCodeLiberacao(event.node.data.user_email);
      } else {
        setQrCodeLiberacao("");
      }
    }
  };

  const handleSubmit = () => {
    console.log(newUser);
    if (newUser !== null && newUser !== "" && newUser !== undefined) {
      agroApi
        .post("/customers/" + User.customers[User.ActiveCustomer].customer_id + "/phones", { phone_description: newUser }, { headers: { token: User.token } })
        .then((resp) => {
          setNewUser("");
          setQrCodeLiberacao("");
          setReload(1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleBlockNuttonClick = (event) => {
    if (userId !== 0) {
      ChangePhoneStatus(userId, 0);
      setNewUser("");
      setQrCodeLiberacao("");
      setUserId(0);
    }
  };

  const handleReleaseButtonClick = (event) => {
    if (userId !== 0) {
      ChangePhoneStatus(userId, 1);
      setNewUser("");
      setQrCodeLiberacao("");
      setUserId(0);
    }
  };

  const handleFechaMostraQRCode = () => {
    setMostraQRCode(false);
  };

  const handleMostraQRCode = () => {
    setMostraQRCode(true);
  };

  return (
    <Grid container style={{ height: "100%", width: "100%" }}>
      <Grid item xs={12} className={classes.root} style={{ height: "70px", width: "100hv" }}>
        <TextField
          id="standard-basic"
          label="Descrição"
          onChange={(event) => setNewUser(event.target.value)}
          value={newUser}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneAndroidIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button type="button" variant="contained" color="secondary" className={classes.button} startIcon={<PhoneAndroidIcon />} onClick={handleSubmit}>
          Cadastrar
        </Button>
      </Grid>
      <Grid item xs={12} className="ag-theme-balham" style={{ height: "65vh", width: "100hv" }}>
        <ButtonGroup variant="contained" size="small" aria-label="outlined primary button group">
          <Button startIcon={<PhonelinkEraseIcon />} onClick={(e) => handleBlockNuttonClick(e)}>
            Bloquear
          </Button>
          <Button startIcon={<PhoneAndroidIcon />} onClick={(e) => handleReleaseButtonClick(e)}>
            Liberar
          </Button>
          <Button disabled={qrCodeLiberacao === ""} startIcon={<CropFreeIcon />} onClick={() => handleMostraQRCode()}>
            Mostrar Autorização
          </Button>
        </ButtonGroup>
        <AgGridReact
          style={{ height: "100%", width: "100%" }}
          //ref="agGrid"
          rowSelection="single"
          animateRows
          rowData={rowData}
          modules={AllCommunityModules}
          //getRowNodeId={this.getRowNodeId}
          onRowSelected={onRowSelected}
          //onGridReady={this.onGridReady}*/
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          columnDefs={[
            { headerName: t("phonegrid.id"), field: "user_id", width: 80, minWidth: 50, maxWidth: 120 },
            {
              headerName: "",
              field: "user_pass",
              width: 50,
              minWidth: 50,
              maxWidth: 50,
              cellRenderer: (data) => {
                if (data.value != null) {
                  return "";
                } else {
                  var element = document.createElement("span");
                  var imageElement = document.createElement("img");
                  imageElement.src = "/images/qrcode.png";
                  element.appendChild(imageElement);
                  return element;
                }
              },
            },
            { headerName: t("phonegrid.name"), field: "user_name", width: 240, minWidth: 240, maxWidth: 340 },
            { headerName: t("phonegrid.email"), field: "user_email", width: 240, minWidth: 240, maxWidth: 240 },
            {
              headerName: t("phonegrid.status"),
              field: "user_status",
              width: 100,
              minWidth: 100,
              maxWidth: 120,
              cellRenderer: (data) => {
                if (data.value === 0) {
                  return "Bloqueado";
                } else {
                  return "Liberado";
                }
              },
            },
            {
              headerName: t("phonegrid.state"),
              field: "user_pass",
              width: 120,
              minWidth: 120,
              maxWidth: 120,
              cellRenderer: (data) => {
                if (data.value != null) {
                  return "Sim";
                } else {
                  return "Não";
                }
              },
            },
            {
              headerName: t("phonegrid.add"),
              field: "user_add",
              width: 200,
              minWidth: 200,
              maxWidth: 200,
              cellRenderer: (data) => {
                if (data.value != null)
                  return moment(data.value)
                    .utc(0)
                    .add(User.customers[User.ActiveCustomer].customer_tz + User.customers[User.ActiveCustomer].customer_dls, "hours")
                    .format("HH:mm DD/MM/YYYY");
                else return null;
              },
            },
            {
              headerName: t("phonegrid.lastlogin"),
              field: "user_lastlogin",
              width: 200,
              minWidth: 200,
              maxWidth: 200,
              cellRenderer: (data) => {
                if (data.value != null)
                  return moment(data.value)
                    .utc(0)
                    .add(User.customers[User.ActiveCustomer].customer_tz + User.customers[User.ActiveCustomer].customer_dls, "hours")
                    .format("HH:mm DD/MM/YYYY");
                else return null;
              },
            },
          ]}
        ></AgGridReact>
      </Grid>
      <Dialog open={mostraQRCode} aria-labelledby="mostraQRCode" aria-describedby="mostraQRCode">
        <DialogTitle id="mostraQRCode">{"Autorização"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="confirmaSelectedIndex">Leia o QRCode para autorizar o app no celular.</DialogContentText>
          {qrCodeLiberacao !== "" && <QRCode size={150} value={qrCodeLiberacao}></QRCode>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleFechaMostraQRCode()} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
